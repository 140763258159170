@import '../../constants';
@import '../../common';
@import '../../mixins';

.wrapper {
  @extend %padding-large;

  margin-bottom: 1rem;

  @extend %thin-border;
  background-color: $color-white;
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $color-lightgray;

  img {
    @extend %margin-right-medium;

    height: 2rem;
  }
}

.row {
  @extend %data-row;
  @extend %padding-medium;

  margin: 1rem 0 0;
  border-left: 0.5rem solid $color-error;
  border-radius: 0.5rem;

  h4 {
    color: $color-error;
  }
}
