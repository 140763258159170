@import '../../common';

.link {
  @extend %button;

  display: inline-flex;
  align-items: center;
  margin-top: 2rem;
  padding: 1rem 3rem;

  background-color: $color-primary;
  color: $color-white;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: $color-primary-hover;
  }

  &:visited {
    color: $color-white;
  }

  > span {
    flex: 1 0 auto;
  }

  > img {
    flex: 1 1 auto;
    height: 2rem;
  }
}
