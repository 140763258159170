@import '../../constants';
@import '../../mixins';

.error {
  padding: 1rem;
  background-color: $color-error-background;
  border-radius: 0.5rem;

  @include medium {
    padding: 2rem;
  }
}
