@import '../../constants';
@import '../../common';
@import '../../mixins';

.data,
.dataPending {
  @extend %data-row;

  grid-area: data;
  margin: 1rem 0;
  border-radius: 0.5rem;

  @include medium {
    margin-top: -2rem;
  }
  @include large {
    margin-top: -3rem;
  }

  h2 {
    @extend %font-medium;

    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  > button,
  > .button {
    flex: 0 0 auto;
  }
}

.button {
  @extend %link-button;
  margin-top: 0;
  background-color: $color-primary;

  &:hover,
  &:focus {
    background-color: $color-primary-hover;
  }
}

.data {
  border-left: 0.5rem solid $color-primary;
  h2 {
    color: $color-primary;
  }
}

.dataPending {
  border-left: 0.5rem solid $color-warning;
  h2 {
    color: $color-warning;
  }
}

.description {
  display: flex;
  align-items: center;

  img {
    @extend %margin-right-medium;

    height: 2rem;
  }
}
