@import '../../constants';
@import '../../common';
@import '../../mixins';

%btn {
  @extend %link-button;
  display: block;
  margin-top: 1rem;
  text-align: center;
}

.h3 {
  margin: 2rem 0 0;
}

.buttons {
  @include small {
    display: flex;
    flex-flow: row wrap;
  }
}

.cancel {
  @extend %btn;
  background-color: $color-white;
  color: $color-secondary;
  border: 1px solid $color-secondary;

  &:hover,
  &:focus {
    background-color: $color-white;
    box-shadow: 0 0 4px 0 $color-secondary;
  }

  &:visited {
    color: $color-secondary;
  }

  @include small {
    margin-right: 1rem;
  }
}

.update {
  @extend %btn;
}
