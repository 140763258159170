@import '../../constants';
@import '../../common';

.overlay {
  @extend %overlay;
}

.modal {
  @extend %modal;

  text-align: left;

  @include medium {
    top: 4rem;
    max-width: 60rem;
    max-height: calc(100vh - 8rem);
    border-radius: 0.5rem;
    box-shadow: $box-shadow;
  }
}

.body {
  padding: 1rem 2rem;

  h4 {
    margin: 0 auto 1rem;
    padding: 0 2rem;
  }

  p {
    padding: 0 1rem;
    text-align: left;

    &.warning {
      text-align: center;
      color: $color-error;
      font-weight: $font-bold;
    }
  }

  > :last-child {
    padding-bottom: 2rem;
  }

  .link {
    @extend %button;
    @extend %margin-right-medium;

    background-color: $color-secondary;
    color: $color-white;
    text-decoration: none;
    text-align: center;
    width: fit-content;
    margin-left: 1rem;

    &:hover,
    &:focus {
      background-color: $color-secondary-hover;
    }
  }

  .buttonContainer {
    width: 100%;
    text-align: center;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
