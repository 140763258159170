@import '../../constants';
@import '../../common';

%choice {
  @extend %padding-medium;

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  flex: 1 1 33.33%;

  &:not(:last-child) {
    border-bottom: 1px solid $color-lightgray;
  }

  @include small {
    @include thin-border;
    background-color: $color-white;

    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  > div {
    max-width: 100%;
  }

  img {
    display: block;
    margin: 0 auto 1rem;
    height: 4rem;
  }

  p {
    margin: 1rem auto;
    padding: 0 0.5rem;
    line-height: 1.2;
  }

  .link {
    @extend %button;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: $color-secondary;
    color: $color-white;
    text-decoration: none;
    text-align: center;

    &:hover,
    &:focus {
      background-color: $color-secondary-hover;
    }
  }
}

.updated {
  dt,
  dd {
    display: inline;
    font-weight: $font-bold;
    color: $color-lightblack;
  }
  dt {
    margin-right: 0.5rem;
  }
}

.title {
  margin: 2rem 0 0.5rem;
}

.description {
  margin: 2rem 0;
  line-height: 1.2;
}

.choices {
  @include small {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
  }
}

.choice {
  @extend %choice;
}

.disabled {
  @extend %choice;

  background-color: $color-gray;
  cursor: not-allowed;

  .link {
    background-color: $color-lightblack;
    color: $color-gray;
    pointer-events: none;
  }
}
