@import 'constants';
@import 'mixins';

%font-small {
  font-size: 0.8125rem;
}

%font-default {
  font-size: 1rem;
}

%font-medium {
  font-size: 1.25rem;
}

%font-large {
  font-size: 1.5rem;
}

%font-huge {
  font-size: 2rem;
}

%font-hero {
  font-weight: $font-semibold;
  font-size: 2.5rem;
}

%padding-small {
  padding: 0.5rem;
}

%padding-medium {
  padding: 1rem;
}

%padding-large {
  padding: 2rem;
}

%padding-huge {
  padding: 3rem;
}

%margin-right-medium {
  margin-right: 1rem;
}

%h1 {
  @extend %font-huge;

  font-weight: $font-semibold;
}

%h2 {
  @extend %font-large;

  font-weight: $font-semibold;
}

%h3 {
  @extend %font-medium;

  font-weight: $font-semibold;
}

%h4 {
  @extend %font-default;

  font-weight: $font-semibold;
}

%h5 {
  @extend %font-small;

  font-weight: $font-semibold;
  text-transform: uppercase;
}

%h6 {
  @extend %font-small;

  font-weight: $font-normal;
  text-transform: uppercase;
}

%thin-border-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid $color-lightgray;
  border-bottom: none;
}

%thin-border-bottom {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: 1px solid $color-lightgray;
  border-top: none;
}

%thin-border {
  @include thin-border;
}

%button {
  border-radius: 0.25rem;
  padding: 0.5rem 1.5rem;
  font-weight: $font-semibold;
  cursor: pointer;
  transition: $transition;
  border: none;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

%link-button {
  @extend %button;

  display: inline-block;
  margin-top: 2rem;
  background-color: $color-secondary;
  color: $color-white;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: $color-secondary-hover;
  }

  &:visited {
    color: $color-white;
  }
}

%link-button-secondary {
  @extend %button;

  display: inline-block;
  margin-top: 2rem;
  border: 1px solid $color-secondary;
  background-color: $color-white;
  color: $color-secondary;
  text-decoration: none;

  &:hover,
  &:focus {
    box-shadow: 0 0 4px 0 $color-secondary;
  }
}

%main-background {
  background-color: $color-darkwhite;
}

%input-text {
  margin-bottom: 0.5rem;
  padding: 1rem;
  min-width: 100%;
  border: 1px solid $color-gray;
  border-radius: 0.25rem;
}

%card-title {
  border-bottom: 1px solid $color-lightgray;
  padding-bottom: 0.75rem;

  img {
    height: 1.5rem;
    width: auto;
    margin-right: 0.5rem;
    vertical-align: bottom;
  }

  @include medium {
    padding-bottom: 0.8125rem;
  }

  @include large {
    padding-bottom: 1rem;
  }
}

%card {
  @extend %thin-border;
  margin-bottom: 2rem;
  background-color: $color-white;
  padding: 1rem;

  @include medium {
    padding: 3rem;
  }
}

%id-card {
  max-width: 32rem;
  margin: 0 auto;

  @include medium {
    max-width: unset;
    margin: 0 1.5rem 0 0;
    width: 22rem;
    flex: 0 0 auto;
  }
}

%id {
  border-radius: 1.25rem;
  margin-bottom: 2rem;
  padding: 0;
  box-shadow: 0 5px 15px 1px $color-lightgray;
  border: none;
}

%table {
  margin-bottom: 2rem;
  padding: 1rem;
  border-collapse: collapse;
  width: 100%;

  thead,
  tr {
    padding: 1rem 0;
  }

  tbody > tr:not(:last-child) {
    border-bottom: 1px dashed $color-gray;
  }

  th,
  td {
    text-align: left;
    padding: 0.5rem 0.5rem;

    @include large {
      padding: 1.5rem 0.75rem;
    }
  }

  @media screen and (max-width: $medium) {
    margin-bottom: 1rem;
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead {
      padding: 0;
    }

    thead tr {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tbody {
      & > tr {
        border: none;

        & > th[scope='row'] {
          border: 1px solid $color-gray;
          border-bottom: none;

          div {
            margin: 0 auto;
          }
        }
      }
    }

    td {
      position: relative;
      padding-top: 3rem;
      padding-left: 1rem;
      border: 1px solid $color-gray;
      border-top: none;
      word-break: break-all;

      &:first-of-type {
        border-top: 1px solid $color-gray;
      }
    }

    tbody td:before {
      position: absolute;
      top: 1rem;
      left: 1rem;
      white-space: nowrap;
      font-weight: $font-semibold;
    }

    tbody td:before {
      content: attr(title);
    }
  }
}

%side-nav-wrapper {
  @include medium {
    display: flex;
  }
}

%sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

%overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background-color: $color-gunpowder-25;
}

%modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background-color: $color-white;

  overflow-y: auto;

  @include medium {
    top: 2rem;
    left: 2rem;
    right: 2rem;
    bottom: unset;
    max-width: 62.5rem;
    max-height: calc(100vh - 4rem);
    margin: auto auto;
  }
}

%modal-body {
  padding: 1rem;

  @include medium {
    padding: 2rem;
  }
  @include large {
    padding: 4rem;
  }

  h3 {
    color: $color-secondary;
    margin-bottom: 3rem;
  }

  h4 {
    margin-bottom: 1rem;
  }

  p {
    margin-top: 4rem;

    button {
      margin-left: 1rem;
    }
  }
}

%link {
  text-decoration: none;
  font-weight: $font-semibold;

  color: $color-secondary;
  cursor: pointer;

  transition: $transition;

  &:visited {
    color: $color-secondary-visited;
  }

  &:hover,
  &:focus {
    color: $color-secondary-hover;
    text-decoration: underline;
  }
}

%modal-delete-header {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  padding: 2rem 0;

  background-color: $color-secondary;

  img {
    display: block;
    margin: 0 auto;
    height: 3.5rem;
    width: auto;
  }
}

%modal-delete-buttons {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;

  button {
    flex: 0 0 calc(50% - 0.5rem);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

%main-security {
  flex: 1 1 auto;
  margin: 1rem 0rem;
  max-width: 72rem;
  padding: 2.5rem;
  background-color: $color-white;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    width: 100%;
  }

  @include small {
    margin: 1rem;
  }

  @include medium {
    margin-top: 2rem;
  }
}

%ccpa {
  max-width: 50rem;
  min-height: 30rem;
  margin: 0 auto;
  padding: 1rem;

  @include small {
    padding-top: 4rem;
  }

  h2 {
    @extend %font-default;

    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
}

%data-row {
  @extend %padding-large;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 4rem;
  margin-bottom: 1rem;
  border: 1px solid $color-gray;

  background-color: $color-white;
}
