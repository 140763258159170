@import '../../constants';
@import '../../common';

.image {
  margin: 1rem 0;
}

.headline {
  margin: 1rem 0 2rem;
}
.error {
  color: $color-error;
}

.strong {
  font-weight: $font-bold;
}

.buttons {
  display: flex;

  > :first-child {
    @extend %margin-right-medium;
  }
}

.link {
  @extend %link-button;
}

.linkSecondary {
  @extend %button;

  border: 1px solid $color-gray;
  background-color: $color-white;
  color: $color-secondary;
  text-decoration: none;

  &:hover,
  &:focus {
    box-shadow: 0 0 4px 0 $color-secondary;
  }
}
