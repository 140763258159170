@import '../common';

.page {
  @extend %main-background;
}

.content {
  @extend %ccpa;

  h1 {
    margin: 3rem 0;
  }

  section {
    margin-top: 2rem;
  }

  .languageButton {
    width: auto;
    display: inline-block;
  }
}
