@import '../common';

.page {
  @extend %main-background;
}

.content {
  @extend %ccpa;

  h1 {
    margin: 3rem 0;
  }
}
