@import '../../constants';
@import '../../common';
@import '../../mixins';

.form {
  @extend %thin-border;
  margin-top: 1rem;
  background-color: $color-white;

  @include medium {
    padding: 2rem;
  }

  h1 {
    margin-top: 0;
  }

  label {
    margin: 1rem 0 2rem;
  }
}
