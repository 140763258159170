@import '../../common';

.spinner {
  display: block;
  margin: 2rem auto;

  @include small {
    margin-top: 4rem;
  }
}
.image {
  margin: 1rem 0;
}
.link {
  @extend %link-button;
}
